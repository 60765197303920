import React from 'react'
import "./style.css"
import Header from '../Header'
import Footer from '../Footer'

export default function Solution() {
    return (
        <>
        
            <Header />
            <div>

                <div className="solutions-container">
                    <h2 className="solutions-heading">Solutions</h2>

                    <main>
                        <section className="section">
                            <h6>Infrastructure Management</h6>
                            <p>GSK Technology provides an end-to-end IT infrastructure management service to help you build a future-proof technological solution for your business</p>
                            <p>The importance of your the data of your company and the technology infrastructure that receives, sends, captures, stores and retrieves that data would be difficult to over-emphasize. Even momentary downtime can have a significant impact on your operations, your service, and your reputation.</p>
                            <p>However, to monitor and stay abreast of all tech applications deployed in your organization would require a disproportionate amount of time and resources than most companies can spare. At GSK Technology, we simplify this process by providing dedicated account support and deploying technology with the core purpose of managing the organizational tasks, not because the technology is the latest.</p>
                            <p>We provide a full range of IT infrastructure solutions designed around a central objective – enabling you to keep doing what you do best – knowing that your technology systems are operating at peak efficiency.    There are seven major components that must be coordinated to provide the firm with a coherent IT infrastructure, as shown in the diagram above.</p>
                            <p>We customize our solutions to suit your requirements and unique environment. Our implementation steps include:</p>
                            <ul>

                                <li>Develop, integrate, stress test, and fine-tune complex IT solutions.</li>
                                <li>Analyze systems, networks, and applications to pinpoint performance enhancements.</li>
                                <li>Rapidly deploy or enhance networks to support business initiatives.</li>
                                <li>Integrate and converge voice, video, and data services.</li>
                                <li>Provide Systems Management Consulting and Design services.</li>
                            </ul>
                        </section>

                        <section className="section">
                            <h6>Servers & Storage</h6>
                            <p>The digital future won’t wait – enter the modern enterprise with high availability, efficiency and scalability.</p>


                            <p>GSK Technology provides a full range of server- and storage-related services, from complete managed server administration to the installation of routine updates and patches. Our cost-effective solutions for Hitachi Unified Compute Platform and Content Platform support that transition on your terms – no matter where you are in your journey.</p>
                            <p>We will review, select and procure a new server and related equipment for your organization based on our knowledge and experience, and combined with professional affiliation with world-class vendors such as Hitachi Data Systems.</p>

                            <p>Look to us for server and storage services including:</p>
                            <ul>
                                <li>IT architecture design, selection, procurement, installation, and configuration.</li>
                                <li>Virtualization and Storage Area Networks (SANs) and Network Attached Storage (NAS).</li>
                                <li>System redundancy, OS installation, and upgrades.</li>
                                <li>Firewall configuration and anti-malware solutions.</li>
                                <li>Monitoring, alerts, and performance optimization.</li>
                            </ul>
                        </section>

                        <section className="section">
                            <h6>Backup Solutions</h6>
                            <p>
                                Data is a critical element in business. GSK Technology offers solutions to prevent data loss and downtime.
                            </p>
                            <p>
                                Instead of buying an expensive server, you can also set your company’s server on a cloud system, which provides a safe online storage environment.
                            </p>
                            <ul>
                                <li>Cloud backup solutions.</li>
                                <li>Cost-effective backup options that scale with usage.</li>
                                <li>Safer alternatives to external hard drives.</li>
                                <li>Dedicated backup solutions to ensure data safety.</li>
                            </ul>
       

                        </section>
                    </main>


                </div>
            </div>
            <div>
                <Footer />
            </div>
        </>
    )
}
